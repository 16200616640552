import React from 'react';
import './mensaje.css'

const Mensaje = () => (
  <div className='mensaje'>
    <h2>¡Si quiero!</h2>
    <p>
    ¡Dicen que de una boda sale otra boda! y la de Ángel y Patricia no podía ser la excepción...
    </p>
    <p>
    El pasado 9 de junio nos prometimos, y celebraremos nuestra unión el próximo 9 de septiembre, nos encantaría contar con vuestra presencia en este día tan especial para nosotros.
     </p>
  
    <p>
    Además, ¡aprovecharemos para compartir con vosotros una gran noticia!
    </p>
    <p>
    ¡Os esperamos!
    </p>
  </div>
);

export default Mensaje;
