import React, { useState } from 'react';
import './NumeroCuenta.css';

function NumeroCuenta() {
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const numeroCuenta = 'ES77 0049 4374 8023 1006 4948';

  const manejarClick = () => {
    setMostrarInfo(true);
  };

  return (
    <div className="numero-cuenta-container">
      {!mostrarInfo && <button onClick={manejarClick}>¿Qué os regalo?</button>}
      {mostrarInfo && (
        <>
          <p className="numero-cuenta-desc">
            Valoramos la originalidad y las sorpresas, por lo que cualquier regalo, desde los más creativos hasta los más simples, será enormemente apreciado. Si prefieres la practicidad, también puedes hacer una contribución a través de nuestro número de cuenta, ¡gracias por formar parte de nuestra vida!
          </p>
          <p className="numero-cuenta">{numeroCuenta}</p>
        </>
      )}
    </div>
  );
}

export default NumeroCuenta;
