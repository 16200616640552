import React from 'react';
import Countdown from './components/Countdown';
import Itinerary from './components/Itinerary';
import RSVPForm from './components/RSVPForm';
import Footer from './components/Footer'
import NumeroCuenta from './components/NumeroCuenta'
import Mensaje from './components/Mensaje'
import './App.css'

function App() {
  return (
    <div >

      <section id="countdown">
        <Countdown />
      </section>

      <section id="mensaje">
        <Mensaje />
      </section>

      <section id="itinerary">
        <Itinerary />
      </section>

      <section id="cuenta">
        <NumeroCuenta />
      </section>

      <section className='formulario' id="rsvp">
        <RSVPForm />
      </section>

      <section id="footer">
        <Footer />
      </section>
    </div>
  );
}

export default App;
