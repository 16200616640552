import React, { useEffect, useState, useRef } from "react";
import "./itinerary.css";

const Itinerary = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const itineraryRef = useRef();

  const handleScroll = () => {
    const totalScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = (window.pageYOffset / totalScrollHeight) * 100;
    setScrollPosition(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openLocation = (latitude, longitude) => {
    const locationURL = `https://maps.google.com/maps?q=${latitude},${longitude}`;
    window.open(locationURL, '_blank');
  };

  return (
    <div className="itinerary" ref={itineraryRef}>
      <h3>Sábado 09 de Septiembre</h3>
      <div className="progressBar">
        <div className="progressBarFilled" style={{ height: `${scrollPosition}%` }}></div>
      </div>
      <div className="event">
        <img src="/church-solid.svg" alt="Logo" className="eventLogo" />
        <h4>Ceremonia</h4>
        <p>17:30 <br/> Parroquia Santiago Apostol</p>
        <button onClick={() => openLocation(37.7972401, -0.8047958)}>Como llegar</button>
      </div>
      <div className="event">
        <img src="/champagne-glasses-solid.svg" alt="Logo" className="eventLogo" />
        <h4>Celebración</h4>
        <p>19:30 <br/>Finca Los Montesinos</p>
        <button onClick={() => openLocation(37.886639, -0.886833)}>Como llegar</button>
      </div>
      {/* Add more events here */}
    </div>
  );
};

export default Itinerary;







